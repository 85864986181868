export const projectStrings = {
    //headers
    marketing: 'Free Marketing Certificate Courses',
    learners: 'Our Learners love GrowthSchool',
    uiux: 'Free UI/UX Certificate Courses',
    learnersHear : 'Success Stories',
    product: 'Free Product Courses',
    successStories: 'Success Stories',
    dataCourses: 'Free Data Courses',
    businessCourses: 'Free Business Courses',
    // 
    upskill: (industry?: string) => `Free ${industry ?? `Online Certificate`} Courses: Upskill & Build a Career Advantage`,
    upskillSubheading: 'Experience Excellence in learning with our Expert-Led Free Courses',
    selectIndustry: 'Select the Industry you want to explore',
    upSkillSubIndustry : 'Upskill & Build a Career in Product Management with Free Online Certificate Courses',

    // certificate strings
    ask: 'Before you ask, we do give certificates!',
    certified: 'Get certified upon course completion and supercharge your career journey.',
    companies: 'Created by Industry Experts working at brands like',

    // questions
    whyGs: 'GrowthSchool is where you become the Top 1% in your field. We bring the best of Product, Growth, Design, Tech, data and business mentors from brands like Google, Meta, Uber etc doing the jobs you want to do tomorrow.',

    // faq

    faqHeading : 'Frequently Asked Questions',
    
    // free course
    freeCourseHeading : 'All Free courses',

    // topics
    topicHeading : 'Learn Online with Free Topics: Career Resources by Industry Experts',


    // footer strings
    footerHeading: "Best kept secrets ✨",
    footerText : 'Be the first to find out about the newest programs, workshops, and community activities - plus industry news, articles, and special reports. All delivered to your inbox!',
    footerRights : '© 2024 Sisinty Pvt. Ltd. All rights reserved.',
    footerSession : 'The session timings are following your local timezone',
}

export const footerItems = {
    "Industries" : ["Product", "Growth", "Marketing", "Data", "Technology", "Business"],
    "Roles" : ["Individuals", "Founders"],
    "Engage" : ["Growth School for Teams", "Apply as Mentor"],
    "Company" : ["About Us", "Careers", "Terms of Service", "Refund Policy", "Privacy Policy", "Community Guidelines"]
}