

const prefix = 'https://gs-website.gumlet.io';

const assetPrefix = 'https://s3.ap-south-1.amazonaws.com/assets.growthschool.io/images/'

export const projectImages = {
    arrowDownTransition: `${prefix}/arrowDownTransition.svg`,
    arrowRightCircled: `${prefix}/arrowRightCircled.svg`,
    breadcrumbHomeIcon: `${prefix}/homeicon.svg`,
    breadcrumbRightArrow: `${prefix}/breadcrumbRightArrow.svg`,
    bulletListLight: `${prefix}/bulletListLight.svg`,
    businessIcon: `${prefix}/businessIcon.svg`,
    buttonGlare: `${prefix}/buttonGlare.svg`,
    calendar: `${prefix}/calendar.svg`,
    chaptersIcons: `${prefix}/chaptersIcons.svg`,
    chart: `${prefix}/chart.svg`,
    chartIconEmpty: `${prefix}/chartIconEmpty.svg`,
    clock: `${prefix}/clock.svg`,
    crossIconLight: `${prefix}/crossIconLight.svg`,
    ctaRightArrowDark: `${prefix}/ctaRightArrowDark.svg`,
    dataIcon: `${prefix}/dataIcon.svg`,
    documentIcon: `${prefix}/documentIcon.svg`,
    documentIconDark: `${prefix}/documentIconDark.svg`,
    documentIconGrey: `${prefix}/documentIconGrey.svg`,
    doubleArrowDownWhite: `${prefix}/doubleArrowDownWhite.svg`,
    downloadSquare: `${prefix}/downloadSquare.svg`,
    faqArrowDown: `${prefix}/faqArrowDown.svg`,
    fbIconLight: `${prefix}/fbIconLight.svg`,
    fourElementsIconDark: `${prefix}/fourElementsIconDark.svg`,
    hamburger: `${prefix}/hamburger.svg`,
    industryFilterIcon: `${prefix}/industryFilterIcon.svg`,
    instaIconLight: `${prefix}/instaIconLight.svg`,
    linkedinIconLight: `${prefix}/linkedinIconLight.svg`,
    linkedInLogoDark: `${prefix}/linkedInLogoDark.svg`,
    marketingIcon: `${prefix}/marketingIcon.svg`,
    nsqLogo: `${prefix}/nsqLogo.svg`,
    nsqRightArrow: `${prefix}/nsqRightArrow.svg`,
    OwlVenturesLogoDark: `${prefix}/OwlVenturesLogoDark.svg`,
    paginationLeftArrowDefault: `${prefix}/paginationLeftArrowDefault.svg`,
    paginationLeftIconDisabled: `${prefix}/paginationLeftIconDisabled.svg`,
    paginationRightArrowDefault: `${prefix}/paginationRightArrowDefault.svg`,
    paginationRightArrowDisabled: `${prefix}/paginationRightArrowDisabled.svg`,
    PenIcon: `${prefix}/PenIcon.svg`,
    playButtonLightBg: `${prefix}/playButtonLightBg.svg`,
    productIcon: `${prefix}/productIcon.svg`,
    quizCupIcon: `${prefix}/quizCupIcon.svg`,
    quizMemoryIcon: `${prefix}/quizMemoryIcon.svg`,
    quizSkillIcon: `${prefix}/quizSkillIcon.svg`,
    ratingStar: `${prefix}/ratingStar.svg`,
    readMoreArrowRight: `${prefix}/readMoreArrowRight.svg`,
    searchCross: `${prefix}/searchCross.svg`,
    searchIcon: `${prefix}/searchIcon.svg`,
    searchIconLight: `${prefix}/searchIconLight.svg`,
    sequoiaLogoDark: `${prefix}/sequoiaLogoDark.svg`,
    shareIconFilled: `${prefix}/sharenormal.svg`,
    techIcon: `${prefix}/techIcon.svg`,
    twitterIconLight: `${prefix}/twitterIconLight.svg`,
    userSquareDark: `${prefix}/userSquareDark.svg`,
    verifiedIcon: `${prefix}/verifiedIcon.svg`,
    videoSquare: `${prefix}/videoSquare.svg`,
    gsLogo: `${prefix}/Gs-logo.svg`,
    checkboxEmpty: `${prefix}/checkboxEmpty.svg`,
    checkboxFilled: `${prefix}/checkboxFilled.svg`,
    socialD : `https://outskill-mentors.gumlet.io/website-assets/socialProof.jpg`,
    socialProofLogosDesktop: `${prefix}/socialProofLogosDesktop.png`,
    socialProofLogosMobile: `${prefix}/socialProofLogosMobile.png`,
    leftArrowCircledLight: `${prefix}/LeftArrowCircledLight.svg`,
    rightArrowCircledLight: `${prefix}/RightArrowCircledLight.svg`,
    earthIcon: `${prefix}/earthIcon.svg`,
    GSLogoFullgswhite: `${prefix}/GS Logo Fullgswhite.svg`,
    NSQhoveranimationnextstep: `${prefix}/NSQ hover animationnextstep.svg`,
    Buttonsubscribe: `${prefix}/Buttonsubscribe.svg`,
    NSQMobile: `${prefix}/NSQmobile.svg`,
    closeiconmedium: `${prefix}/closeiconmedium.svg`,
    closeIconSmall: `${prefix}/closeIconSmall.svg`,
    greenTick: `${prefix}/green-tick.svg`,
    facebook: `${prefix}/FacebookShareLogo.svg`,
    linkedin: `${prefix}/LinkedInShareLogo.svg`,
    twitter: `${prefix}/TwitterShareLogo.svg`,
    LeftArrowCircledDark: `${prefix}/LeftArrowCircledDark.svg`,
    rightArrowCircledDark: `${prefix}/card-light-arrow-right.svg`,
    shareHover: `${prefix}/ShareDark.svg`,
    nsqHover: `${prefix}/nsqhover.svg`,
    fbDark: `${prefix}/Facebookfbdark.svg`,
    twitterDark: `${prefix}/TwittertwitterDark.svg`,
    linkedinDark: `${prefix}/LinkedInlinkedinDark.svg`,
    paginationArrowRightLight: `${prefix}/paginationArrowRightLight.svg`,
    chartAdvanced: `${prefix}/chartAdvanced.svg`,
    marketingIconRight: `${prefix}/marketingRight.svg`,
    learnDownDark: `${prefix}/learnDark.svg`,
    leftArrowDark: `${prefix}/leftArrowDark.svg`,
    rightArrowDark: `${prefix}/rightArrowDark.svg`,
    certificateFill: `${prefix}/GS certificate (1).jpg`,
    investor1: `${prefix}/investor1.jpg`,
    investor2: `${prefix}/investor2.jpg`,
    investor3: `${prefix}/investor3.jpg`,
    paginationdark: `${prefix}/paginationdark.svg`,
    pause: `${prefix}/pause.svg`,
    cardarrowdown: `${prefix}/cardarrowdown.svg`,
    cardarrowright: `${prefix}/cardarrowright.svg`,
    glare: `${prefix}/glare.jpg`,
    mentorfallback: `${prefix}/mentorfallback.svg`,
    chartIntermediate: `${prefix}/chartIntermediate.svg`,
    chartBeginner: `${prefix}/chartBeginner.svg`,
    seeAllNormal: `${prefix}/see-all-normal.svg`,
    seeAllHover: `${prefix}/see-all-hover.svg`,
    mobilepaginationnormal: `${prefix}/mobilepaginationnormal.svg`,
    mobilepaginationdisabled: `${prefix}/mobilepaginationdisabled.svg`,
    certificateNew: `${prefix}/certificate-new.svg`,
    topicscompanies: `${prefix}/topicscompanies.svg`,
    arrowCircledFilled: `${prefix}/arrow-right-circled-filled.svg`,
    downloadIconOutlined: `${prefix}/download-icon-outlined.svg`,
    squaresOutlined: `${prefix}/square-boxes-outlined.svg`,
    cardcompanies: `${prefix}/cardcompanies.svg`,
    mentors: `${prefix}/mentors.svg`,
    createText: `${prefix}/vettedBy.svg`,
    mobileLine: `${prefix}/mobileLine.svg`,
    freeThumbnail: (index: number) => `${prefix}/Free-+Course+Thumbnail+${index}.jpg`,
    premiumThumbnail: (index: number) => `${prefix}/Premium Thumbnail ${index}premium.jpg`,
    tableOfContent: `${prefix}/TOC.svg`,
    AI: `${prefix}/AI.svg`,
    certificateMentor: `${prefix}/certificate.jpg`,
    mobileThumbnail : (index: number) => `https://outskill-mentors.gumlet.io/website-assets/thumnailMobile-${index}.jpg`,
    inves1: `${prefix}/investor1.jpg`,
    inves2: `${prefix}/investor2.jpg`,
    inves3: `${prefix}/investor3.jpg`,
    nextStepC : `${prefix}/nextstepmobile.jpg`
}

export const homePageImages: any = {
    'cbig': `${assetPrefix}1490 Background Removed 2cbig.svg`,
    '1raj': `${assetPrefix}1raj.svg`,
    '1tanmaybut': `${assetPrefix}1tanmaybut.svg`,
    '1wari': `${assetPrefix}1wari.svg`,
    '2couple': `${assetPrefix}2couple.svg`,
    '2raj': `${assetPrefix}2raj.svg`,
    '2tanmaybut': `${assetPrefix}2tanmaybut.svg`,
    '2wari': `${assetPrefix}2wari.svg`,
    'linkedin-testimonial': `${assetPrefix}linkedin-testimonial.svg`,
    'office-hours': `${assetPrefix}+office-hours.svg`,
    'play-hours': `${assetPrefix}play-hours.svg`,
    'skillupbrands': `${assetPrefix}+skillupbrands.svg`,
    'twitter': `${assetPrefix}twitter.svg`,
    'acess': `${assetPrefix}acess.svg`,
    'Arrow_rightcrashcoursearrowright': `${assetPrefix}Arrow_rightcrashcoursearrowright.svg`,
    'arrow-circle-leftarrowdisright': `${assetPrefix}arrow-circle-leftarrowdisright.svg`,
    'arrowidleleft': `${assetPrefix}arrowidleleft.svg`,
    'arrowleftgreen': `${assetPrefix}arrowleftgreen.svg`,
    'arrowrightgreen': `${assetPrefix}arrowrightgreen.svg`,
    'brands': `${assetPrefix}brands.svg`,
    'buttongreen': `${assetPrefix}buttongreen.svg`,
    'Buttonseeallgreen': `${assetPrefix}Buttonseeallgreen.svg`,
    'Buttonseeallhovergreen': `${assetPrefix}Buttonseeallhovergreen.svg`,
    'Calendarcalender': `${assetPrefix}Calendarcalender.svg`,
    'Chevron_rightmoadlarrowright': `${assetPrefix}Chevron_rightmoadlarrowright.svg`,
    'couple': `${assetPrefix}couple.svg`,
    'Defaulttwitterhover': `${assetPrefix}Defaulttwitterhover.svg`,
    'earthhome': `${assetPrefix}earthhome.svg`,
    'exlpre': `${assetPrefix}exlpre.svg`,
    'Expand_moretestimoial-down': `${assetPrefix}Expand_moretestimoial-down.svg`,
    'Facebookfbhome': `${assetPrefix}Facebookfbhome.svg`,
    'fbhover': `${assetPrefix}fbhover.svg`,
    'GS Logo Fulllogonew': `${assetPrefix}GS Logo Fulllogonew.svg`,
    'ic_outline-currency-rupeeruppehome': `${assetPrefix}ic_outline-currency-rupeeruppehome.svg`,
    'investors': `${assetPrefix}investors.svg`,
    'Kunal - Smallksbig': `${assetPrefix}Kunal - Smallksbig.svg`,
    'Learnerlearners-1': `${assetPrefix}Learnerlearners-1.svg`,
    'Learnerlearners-2': `${assetPrefix}Learnerlearners-2.svg`,
    'Learnerlearners-3': `${assetPrefix}Learnerlearners-3.svg`,
    'Learnerlearners-4': `${assetPrefix}Learnerlearners-4.svg`,
    'Learnerlearners-5': `${assetPrefix}Learnerlearners-5.svg`,
    'Learnerlearners-6': `${assetPrefix}Learnerlearners-6.svg`,
    'Learnerlearners-7': `${assetPrefix}Learnerlearners-7.svg`,
    'Learnerlearners': `${assetPrefix}Learnerlearners.svg`,
    'learngren': `${assetPrefix}learngren.svg`,
    'linkedinhover': `${assetPrefix}linkedinhover.svg`,
    'LinkedInlinkedinhome': `${assetPrefix}LinkedInlinkedinhome.svg`,
    'Livesession': `${assetPrefix}Livesession.svg`,
    'Logogswhitehome': `${assetPrefix}Logogswhitehome.svg`,
    'modalcross': `${assetPrefix}modalcross.svg`,
    'nextstephome': `${assetPrefix}nextstephome.svg`,
    'NSQ banner (hover)nextstephomehover': `${assetPrefix}NSQ banner (hover)nextstephomehover.svg`,
    'NSQ banner (idle)nextstephomenormal': `${assetPrefix}NSQ banner (idle)nextstephomenormal.svg`,
    'owlhome': `${assetPrefix}owlhome.svg`,
    'Patternlearnerbg': `${assetPrefix}Patternlearnerbg.png`,
    'project-driven': `${assetPrefix}project-driven.svg`,
    'quotation': `${assetPrefix}quotation.svg`,
    'Raj Shamanirajshamanihover': `${assetPrefix}Raj Shamanirajshamanihover.svg`,
    'Raj Shamanirajshamaninormal': `${assetPrefix}Raj Shamanirajshamaninormal.svg`,
    'Rectangle 6106warikobig': `${assetPrefix}Rectangle 6106warikobig.svg`,
    'Replay Stateplay-icon': `${assetPrefix}Replay Stateplay-icon.svg`,
    'rsbig': `${assetPrefix}rsbig.svg`,
    'selfpaced': `${assetPrefix}selfpaced.svg`,
    'sequoiahome': `${assetPrefix}sequoiahome.svg`,
    'si_Check_circlewelcomtick': `${assetPrefix}si_Check_circlewelcomtick.svg`,
    'si_Expand_moredropdownarrow': `${assetPrefix}si_Expand_moredropdownarrow.svg`,
    'si_Expand_morereadit': `${assetPrefix}si_Expand_morereadit.svg`,
    'Table_columnstable': `${assetPrefix}Table_columnstable.svg`,
    'Textureleranertextture': `${assetPrefix}Textureleranertextture.svg`,
    'twitterhome': `${assetPrefix}twitterhome.svg`,
    'Vectorglobehover': `${assetPrefix}Vectorglobehover.svg`,
    'Vectorrupehover': `${assetPrefix}Vectorrupehover.svg`,
    'vuesaxarrowidleright': `${assetPrefix}vuesaxarrowidleright.svg`,
    'wariko': `${assetPrefix}wariko.svg`,
    'greengrid' : `${assetPrefix}greengrid.svg`
}